import {
	Button,
	Errors,
	Field,
	FieldDescription,
	FieldErrors,
	FormSpree,
	Heading,
	Input,
	Label,
	Option,
	Radio,
	RadioBar,
	RadioBarButton,
	RadioGroup,
	Select,
	Switch as SwitchField,
	TextField,
	TextLink,
} from '@troon/ui';
import { action, useSubmission } from '@solidjs/router';
import { createSignal, For, Match, Show, Switch } from 'solid-js';
import dayjs from 'dayjs';
import { IconCircleCheck } from '@troon/icons';
import { Content } from '../../../../components/content';
import { GenericLayout } from '../../../../layouts/generic';
import { useUser } from '../../../../providers/user';

export default function RequestATeeTime() {
	const user = useUser();
	const [guests, setGuests] = createSignal(0);
	const [verified, setVerified] = createSignal(false);
	const submission = useSubmission(requestTeeTime);

	return (
		<GenericLayout>
			<Content size="small" class="flex flex-col gap-8">
				<Heading as="h1">Request a tee time</Heading>

				<p>
					Please note that only qualified private club members may request tee times at participating private clubs
					within Troon Privé Privileges. If you have any questions about the program or are experiencing issues
					accessing these benefits, please contact your home club staff.
				</p>

				<p>
					For Visa Infinite Members seeking to request a tee time, please utilize the designated site{' '}
					<TextLink href="https://troongolfrewards.com/verify/visa-infinite?locale=us">US Cardholders</TextLink> or{' '}
					<TextLink href="https://offer.troongolfrewards.com/visainfiniteprivilege?language=en">
						Canada Cardholders
					</TextLink>
					.
				</p>

				<Switch>
					<Match when={submission.result}>
						<div class="container mx-auto flex max-w-xl flex-col items-center gap-6 text-center">
							<IconCircleCheck class="text-8xl text-brand" />
							<Heading as="h1" size="h2">
								Request received!
							</Heading>
							<p class="text-lg">
								Thank you for submitting your request. We have received your Troon Privé member request to reserve a tee
								time at one of our participating private clubs. Your desired destination club will be in touch to
								confirm your reservation.
							</p>
						</div>
					</Match>
					<Match when>
						<FormSpree action={requestTeeTime} method="post" class="flex flex-col gap-6">
							<input type="hidden" name="__formspree" value="mvgpnayr" />
							<div class="flex w-full flex-wrap gap-6">
								<TextField name="First name" required class="grow">
									<Label>First name</Label>
									<Input autocomplete="given-name" value={user()?.me.firstName} />
								</TextField>

								<TextField name="Last name" required class="grow">
									<Label>Last name</Label>
									<Input autocomplete="family-name" value={user()?.me.lastName} />
								</TextField>
							</div>

							<TextField name="Email" required>
								<Label>Email address</Label>
								<Input autocomplete="email" inputMode="email" value={user()?.me.email} />
							</TextField>

							<TextField name="Phone" required>
								<Label>Phone number</Label>
								<Input autocomplete="tel" inputMode="tel" />
							</TextField>

							<Field name="Home club" required>
								<Label>Your home club</Label>
								<Select>
									<Option disabled selected class="hidden" />
									<For each={homeClubs}>{(club) => <Option>{club}</Option>}</For>
								</Select>
								<FieldErrors />
							</Field>

							<TextField name="Home club membership" required>
								<Label>Home club membership #</Label>
								<Input />
							</TextField>

							<Field name="Desired club" required>
								<Label>Club you desire to play</Label>
								<Select>
									<Option disabled selected class="hidden" />
									<For each={playClubs}>{(club) => <Option>{club}</Option>}</For>
								</Select>
								<FieldErrors />
							</Field>

							<SwitchField name="__verified" required onChange={(e) => setVerified(e.target.checked)}>
								<div class="flex flex-col">
									<Label required>I have verified the club’s availability and booking window</Label>
									<FieldDescription>
										Have you verified the host club's availability and booking window, and affirm that your request
										follows those guidelines. You must verify clubs booking window and click yes in order to proceed
										with your request.
									</FieldDescription>
								</div>
							</SwitchField>

							<Show when={verified()}>
								<TextField name="Date and time" required>
									<Label>Date you desire to play</Label>
									<Input type="datetime-local" min={dayjs().format('YYYY-MM-DDThh:mm')} />
								</TextField>

								<RadioGroup name="Number of guests" onSelect={(value) => setGuests(parseInt(value, 10) - 1)}>
									<Label>Total number of guests</Label>
									<RadioBar>
										<RadioBarButton value="1" checked>
											<Label>1</Label>
										</RadioBarButton>
										<RadioBarButton value="2">
											<Label>2</Label>
										</RadioBarButton>
										<RadioBarButton value="3">
											<Label>3</Label>
										</RadioBarButton>
										<RadioBarButton value="4">
											<Label>4</Label>
										</RadioBarButton>
									</RadioBar>
									<FieldDescription>
										Select the total number of guests <em>including yourself</em>.
									</FieldDescription>
								</RadioGroup>

								<Show when={guests() > 0}>
									<p>Please enter the name of each of your accompanying guests</p>
									<For each={[...Array(guests()).keys()]}>
										{(_, i) => (
											<TextField name={`Accompanying guest ${i() + 1}`}>
												<Label>Accompanying guest {i() + 1}</Label>
												<Input />
											</TextField>
										)}
									</For>
								</Show>

								<TextField name="Additional comments">
									<Label>Additional comments</Label>
									<Input multiline />
								</TextField>
							</Show>

							<RadioGroup name="Subscribe to Troon Privé Magazine" required>
								<Label>Please subscribe me to Troon Privé Magazine</Label>
								<Radio value="Yes">
									<Label>Yes</Label>
								</Radio>
								<Radio value="Noe">
									<Label>No</Label>
								</Radio>
							</RadioGroup>

							<RadioGroup name="Receive special offers" required>
								<Label>
									I would like to receive special offers from Troon and its managed facilities and preferred partners
								</Label>
								<Radio value="Yes">
									<Label>Yes</Label>
								</Radio>
								<Radio value="Noe">
									<Label>No</Label>
								</Radio>
							</RadioGroup>

							<Errors />

							<Button type="submit" disabled={!verified()}>
								Submit
							</Button>
						</FormSpree>
					</Match>
				</Switch>
			</Content>
		</GenericLayout>
	);
}

const requestTeeTime = action(async (data: FormData) => {
	const id = data.get('__formspree');
	if (!id) {
		throw new Error('Missing FormSpree ID');
	}
	const body = new FormData();
	for (const key of data.keys()) {
		let value = data.get(key);
		if (typeof value === 'string') {
			value = value.trim();
		}
		if (!key.startsWith('_') && value) {
			body.set(key, value);
		}
	}
	const res = await fetch(`https://formspree.io/f/${id}`, {
		method: 'POST',
		body,
		headers: { accept: 'application/json' },
	});

	const json = await res.json();
	if (!res.ok) {
		throw json;
	}
	return json;
}, 'prive-request-tee-time');

const homeClubs = [
	'Alpine Country Club (RI)',
	'Alpine Country Club (UT)',
	'Arabian Ranches',
	'Audubon Country Club',
	'Austin Country Club',
	'Bahia Beach Resort &amp; Golf Club',
	'BallenIsles Country Club',
	'Bay Creek',
	'Bayside Resort Golf Club',
	'Beechmont Country Club',
	'Belfair',
	'Blackstone Country Club',
	'Bonita National Golf &amp; Country Club',
	'Bookcliff Country Club',
	'Boulders Resort &amp; Spa',
	'Boulder Ridge Golf Club',
	'Broken Top Club',
	'Calvert Crossing Golf Club',
	'Canebrake Country Club',
	'Capital Canyon Club',
	'Centurion Club',
	'Champion Hills',
	'Chateau Golf &amp; Country Club',
	'Chateau Elan Winery and Resort',
	'Cimarron Hills Golf &amp; Country Club',
	'Colonial Country Club (FL)',
	'Columbia Country Club',
	'Columbus Country Club',
	'Comanche Trace',
	'Contra Costa Country Club',
	'Country Club of Colorado',
	'Crow Canyon Country Club',
	'Crystal Lake Country Club',
	'Deering Bay Yacht &amp; Country Club',
	'Dunes of Naples',
	'Eau Claire Golf &amp; Country Club',
	'Echo Valley Country Club',
	'El Macero Country Club',
	'Entrada at Snow Canyon',
	'Esplanade Golf &amp; Country Club',
	'Esplanade Golf &amp; Country Club Lakewood Ranch',
	'Fairbanks Ranch Country Club',
	'Fairwood Golf &amp; Country Club',
	'Fargo Country Club',
	"Fiddler's Creek",
	'Fieldstone Golf Club',
	'Flagstaff Ranch Golf Club',
	'Gadsden Country Club',
	'Hammock Beach',
	'Hunting Creek Country Club',
	'John Newcombe Country Club',
	'Kankakee Country Club',
	'Kemper Lakes Golf Club',
	'Knollwood Country Club (MI)',
	'Lake Valley Golf Club',
	'La Paloma Country Club',
	'Links at Perry Cabin',
	'Lomas Santa Fe Country Club',
	'Manchester Country Club',
	'Mauna Lani',
	'Marin Country Club',
	'Mill Creek Country Club',
	'Naperville Country Club',
	'Newport Beach Tennis Center',
	'NorthRiver Yacht Club',
	'Ocean Club at Hutchinson Island',
	'Ocean Edge Resort &amp; Club',
	'Oldfield Club',
	'Old Overton Club',
	'Oneida Golf &amp; Country Club',
	'Parkland Golf &amp; Country Club',
	'Pelican Lakes Resort and Golf',
	'PGA National Resort',
	'PGA National Czech Republic',
	'Phoenix Country Club',
	'Pine Canyon Club',
	'Pine Tree Country Club',
	'Pinetop Country Club',
	'Polo Tennis and Fitness Club',
	'Pronghorn',
	'RainDance National',
	'Raintree Country Club',
	'Raritan Valley Country Club',
	'Red Hawk Golf and Resort',
	'Red Ledges',
	'Reserva Conchal Club',
	'Rio Mar Country Club',
	'Rio Verde Country Club',
	'Riverton Pointe Golf &amp; Country Club',
	'Rome Downtown Racquet Club',
	'Rome Tennis Center at Berry College',
	'Salina Country Club',
	'Saltaire Yacht Club',
	'Santa Ana Country Club',
	'Santa Maria Golf Club',
	'Santa Rosa Golf &amp; Beach Club',
	'Seminole Legacy Golf Club',
	'Sierra View Country Club',
	'Silver Creek Valley Country Club',
	'Silverado Resort',
	'Somerby Golf Club',
	'Southampton Club and Camp',
	'Southern Trace Country Club',
	'Springdale Golf Club',
	'Sterling Grove Golf &amp; Country Club',
	'StoneTree Golf Club',
	'Sunnyside Golf &amp; Country Club',
	'Talking Rock',
	'Terravita Golf Club',
	'The Club at ArrowCreek',
	'The Club at Castlewood',
	'The Club at Cordillera',
	'The Club at Houston Oaks',
	'The Club at Porto Cima',
	'The Clubs at St. James',
	'The Falls Club',
	'The Fountaingrove Club',
	'The Golf Club at Lansdowne',
	'The Grand Golf Club at Grand Del Mar',
	'The Peninsula Golf &amp; Country Club',
	'The Powder Horn Golf Club',
	'The Ritz-Carlton Bacara, Santa  Barbara',
	'The Ritz-Carlton Key Biscayne',
	'The Standard Club',
	'Tibur\u00f3n Golf Club',
	'Torreon Golf Club',
	'Tuckers Point Club, Bermuda',
	'Tumble Brook Country Club',
	'Tyler Athletic and Swim Club',
	'University Club of Milwaukee',
	'Valley Country Club',
	'Vanderbilt Legends Club',
	'Walden Racquet Center on Lake Conroe',
	'Wausau Country Club',
	'West Bay Club',
	'Westchester Hills Golf Club',
	'Westhaven Golf Club',
	'Westmoreland Country Club',
	'Weston Racquet Club',
	'Wickenburg Ranch Golf &amp; Social Club',
	'Woodbridge Golf &amp; Country Club',
	'Woodholme Country Club',
	'Yorba Linda Country Club',
];

const playClubs = [
	'Alpine Country Club (RI)',
	'Alpine Country Club (UT)',
	'Arabian Ranches',
	'Audubon Country Club',
	'Austin Country Club',
	'Bahia Beach Resort &amp; Golf Club',
	'BallenIsles Country Club',
	'Bay Creek',
	'Bayside Resort Golf Club',
	'Beechmont Country Club',
	'Belfair',
	'Blackstone Country Club',
	'Bonita National Golf &amp; Country Club',
	'Bookcliff Country Club',
	'Boulder Resort &amp; Spa',
	'Boulder Ridge Golf Club',
	'Broken Top Club',
	'Calvert Crossing Golf Club',
	'Canebrake Country Club',
	'Capital Canyon Club',
	'Centurion Club',
	'Champion Hills',
	'Cimarron Hills Golf &amp; Country Club',
	'Colonial Country Club (FL)',
	'Columbia Country Club',
	'Columbus Country Club',
	'Comanche Trace',
	'Contra Costa Country Club',
	'Country Club of Colorado',
	'Crow Canyon Country Club',
	'Crystal Lake Country Club',
	'Deering Bay Yacht &amp; Country Club',
	'Eau Claire Golf &amp; Country Club',
	'Echo Valley Country Club',
	'El Macero Country Club',
	'Entrada at Snow Canyon',
	'Esplanade Golf &amp; Country Club',
	'Esplanade Golf &amp; Country Club Lakewood Ranch',
	'Fairbanks Ranch Country Club',
	'Fairwood Golf &amp; Country Club',
	'Fargo Country Club',
	'Fieldstone Golf Club',
	'Flagstaff Ranch Golf Club',
	'Gadsden Country Club',
	'Hammock Beach',
	'Hunting Creek Country Club',
	'Kankakee Country Club',
	'Kemper Lakes Golf Club',
	'Knollwood Country Club (MI)',
	'Lake Valley Golf Club',
	'La Paloma Country Club',
	'Links at Perry Cabin',
	'Lomas Santa Fe Country Club',
	'Manchester Country Club',
	'Marin Country Club',
	'Mill Creek Country Club',
	'Naperville Country Club',
	'NorthRiver Yacht Club',
	'Ocean Club at Hutchinson Island',
	'Ocean Edge Resort &amp; Club',
	'Oldfield Club',
	'Old Overton Club',
	'Oneida Golf &amp; Country Club',
	'Parkland Golf &amp; Country Club',
	'Pelican Lakes Resort and Golf',
	'PGA National Resort',
	'PGA National Czech Republic',
	'Phoenix Country Club',
	'Pine Canyon Club',
	'Pine Tree Country Club',
	'Pinetop Country Club',
	'Pronghorn',
	'RainDance National',
	'Raintree Country Club',
	'Raritan Valley Country Club',
	'Red Hawk Golf and Resort',
	'Reserva Conchal Club',
	'Rio Mar Country Club',
	'Rio Verde Country Club',
	'Riverton Pointe Golf &amp; Country Club',
	'Salina Country Club',
	'Santa Ana Country Club',
	'Santa Maria Golf Club',
	'Santa Rosa Golf &amp; Beach Club',
	'Seminole Legacy Golf Club',
	'Sierra View Country Club',
	'Silver Creek Valley Country Club',
	'Silverado Resort',
	'Somerby Golf Club',
	'Southern Trace Country Club',
	'Springdale Golf Club',
	'Sterling Grove Golf &amp; Country Club',
	'StoneTree Golf Club',
	'Sunnyside Golf &amp; Country Club',
	'Talking Rock',
	'Terravita Golf Club',
	'The Club at ArrowCreek',
	'The Club at Castlewood',
	'The Club at Cordillera',
	'The Club at Porto Cima',
	'The Club at Savannah Quarters',
	'The Clubs at St. James',
	'The Falls Club',
	'The Fountaingrove Club',
	'The Golf Club at Lansdowne',
	'The Grand Golf Club at Grand Del Mar',
	'The Peninsula Golf &amp; Country Club',
	'The Powder Horn Golf Club',
	'The Ritz-Carlton Key Biscayne',
	'The Standard Club',
	'Tibur\u00f3n Golf Club',
	'Torreon Golf Club',
	'Tumble Brook Country Club',
	'Tyler Athletic and Swim Club',
	'University Club of Milwaukee',
	'Valley Country Club',
	'Vanderbilt Legends Club',
	'Wausau Country Club',
	'West Bay Club',
	'Westchester Hills Golf Club',
	'Westhaven Golf Club',
	'Westmoreland Country Club',
	'Wickenburg Ranch Golf &amp; Social Club',
	'Woodbridge Golf &amp; Country Club',
	'Woodholme Country Club',
	'Yorba Linda Country Club',
];
